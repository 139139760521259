import React from 'react';
import {
  Flex,
  Text,
  useBreakpointValue,
  VStack,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  Button
} from '@chakra-ui/react';
import { AiOutlinePhone, AiOutlineFieldTime, AiOutlineLink } from "react-icons/ai";
import { BiMap } from "react-icons/bi";
import { ChevronDownIcon } from '@chakra-ui/icons';
import ReactGA from "react-ga4"

if (process.env.REACT_APP_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)
}

const handleButtonClick = () => {
  const event = ReactGA.event('phone_call', {
    category: 'Clicks',
    action: 'Phone Call',
    label: 'tel:+15185793153',
  });
  console.log(event)

};

const InfoPopover = ({ title, infoItems, isMobile }) => (
  <Popover>
    <PopoverTrigger>
      <Button variant="link" colorScheme="teal" rightIcon={isMobile ? <ChevronDownIcon/> : null}>
        {title}
      </Button>
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverHeader color={"black"}>{title}</PopoverHeader>
      <PopoverBody>
      {infoItems.map((item, index) => {
           if (item.includes("Call:")) {
            const number = item.split(" ")[1].replace(".", "-");
            return (
              <Text color="black" key={index}>
                <Link onClick={handleButtonClick} href={`tel:${number}`}>{item}</Link>
              </Text>
            );
          }
          if (item.includes("Email:")) {
            const email = item.split(" ")[1];
            return (
              <Text color="black" key={index}>
                <Link href={`mailto:${email}`}>{item}</Link>
              </Text>
            );
          }
          if (item.includes("Lia Insurance Agency") || item.includes("PO Box 5212") || item.includes("Albany, NY 12205")) {
            return ( <Text color="black" key={index}>
               {item}
              </Text>
            )
          }
          return <Text color="black" key={index}>{item}</Text>;
        })}
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

const Footer = ({mb}) => {
    const fontSize = useBreakpointValue({ base: "sm", md: "md" });
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <VStack
            bgColor="gray.800"
            color="white"
            fontFamily="body"
            fontSize={fontSize}
            py={8}
            px={{ base: 5, md: 10 }}
            spacing={6}
            align="stretch"
            mb={mb}
        >
            <Flex
                direction={{ base: "row", md: "row" }}
                justify="space-between"
                wrap="wrap"
            >
                <VStack spacing={2} align="start" mb={{ base: 4, md: 0 }}>
                    <Flex alignItems="center">
                        <Icon as={BiMap} color="teal.300" w={6} h={6} />
                        {isMobile
                            ? <InfoPopover title="Address" isMobile={isMobile} infoItems={[
                                'Lia Insurance Agency',
                                'PO Box 5212',
                                'Albany, NY 12205',
                              ]}/>
                            : <Text ml={2}>Address</Text>
                        }
                    </Flex>
                    {!isMobile && (
                      <>
                        <Text>Lia Insurance Agency</Text>
                        <Text>PO Box 5212</Text>
                        <Text>Albany, NY 12205</Text>
                      </>
                    )}
                </VStack>

                <VStack spacing={2} align="start" mb={{ base: 4, md: 0 }}>
                    <Flex alignItems="center">
                        <Icon as={AiOutlineFieldTime} color="teal.300" w={6} h={6} />
                        {isMobile
                            ? <InfoPopover title="Hours" isMobile={isMobile} infoItems={[
                                'Mon-Fri: 8:30am-5pm',
                                'Sat-Sun: Closed',
                              ]}/>
                            : <Text ml={2}>Hours</Text>
                        }
                    </Flex>
                    {!isMobile && (
                      <>
                        <Text>Mon-Fri: 8:30am-5pm</Text>
                        <Text>Sat-Sun: Closed</Text>
                      </>
                    )}
                </VStack>

                <VStack spacing={2} align="start">
                    <Flex alignItems="center">
                        <Icon as={AiOutlinePhone} color="teal.300" w={6} h={6} />
                        {isMobile
                            ? <InfoPopover title="Contact Us" isMobile={isMobile} infoItems={[
                                'Call: 518.579.3153',
                                'Email: insurance@liagroup.com',
                              ]}/>
                            : <Text ml={2}>Contact Us</Text>
                        }
                    </Flex>
                    {!isMobile && (
                      <>
                        <Link onClick={handleButtonClick} href='tel:518-579-3153'>Call: 518.579.3153</Link>
                        <Link href="mailto:insurance@liagroup.com">Email: insurance@liagroup.com</Link>
                      </>
                    )}
                </VStack>
                
                <VStack spacing={2} align="start" mb={{ base: 4, md: 0 }}>
                    <Flex alignItems="center">
                      <Icon as={AiOutlineLink} color="teal.300" w={6} h={6}/>
                        <Text ml={2}>Quick Links</Text>
                    </Flex>
                    <Link href="/sitemap">Sitemap</Link>
                </VStack>
            </Flex>
        </VStack>
    );
};

export default Footer;



